import React, { useEffect, useState, useCallback } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Spinner,
  Input,
} from "reactstrap"
import ReactPaginate from "react-paginate"
import { connect } from "react-redux"
import axios from "axios"
import { utils, writeFile, read } from "xlsx"
import { useParams, useNavigate } from "react-router-dom"
import { setBreadcrumbItems } from "../../store/actions"
import "./Empresas.scss"

const baseURL = process.env.REACT_APP_API_URL

const Sucursales = props => {
  document.title = "Sucursales | LS-DA - Softlogy"

  const { id } = useParams() // NIT de la empresa
  const navigate = useNavigate()

  const [sucursales, setSucursales] = useState([])
  const [searchTerm] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10
  const [loading, setLoading] = useState(true)
  const [uploading, setUploading] = useState(false)
  const [editIndex, setEditIndex] = useState(null) // Índice de la fila en edición
  const [editedSucursal, setEditedSucursal] = useState(null) // Datos de la sucursal en edición
  const [updatingId, setUpdatingId] = useState(null) // ID de la sucursal siendo actualizada

  const fetchSucursales = useCallback(
    async currentUser => {
      if (!currentUser || !currentUser.access) {
        console.log("User data not fully available yet, skipping fetch.")
        return
      }

      setLoading(true)
      try {
        const response = await axios.get(
          `${baseURL}empresas/sucursales?id=${id}`,
          {
            headers: { Authorization: `Bearer ${currentUser.access}` },
          },
        )
        setSucursales(response.data.sucursales || [])
      } catch (error) {
        console.error("Error fetching sucursales data:", error)
        setSucursales([])
      } finally {
        setLoading(false)
      }
    },
    [id],
  )

  useEffect(() => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null
    if (currentUser) {
      const userGreeting = `Bienvenido, ${currentUser ? `${currentUser.nombre} ${currentUser.apellido}` : "Usuario"}`
      fetchSucursales(currentUser)
      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Empresas", link: "#" },
        { title: "Sucursales", link: "#" },
      ]
      props.setBreadcrumbItems(userGreeting, breadcrumbItems)
    }
  }, [fetchSucursales, props])

  const handleDownloadForm = () => {
    const templateData =
      sucursales.length > 0
        ? sucursales
        : [
            {
              brandid: "",
              storeid: "",
              Nombretienda: "",
              marca: "",
              direccion: "",
              telefono: "",
              nombrecomercial: "",
            },
          ]

    const worksheet = utils.json_to_sheet(templateData)
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, "Sucursales")
    writeFile(workbook, "Formulario_Sucursales.xlsx")
  }

  const handleImportExcel = async event => {
    const file = event.target.files[0]
    if (!file) return

    setUploading(true)
    try {
      const reader = new FileReader()
      reader.onload = async e => {
        const data = new Uint8Array(e.target.result)
        const workbook = read(data, { type: "array" })
        const worksheet = workbook.Sheets[workbook.SheetNames[0]]
        const jsonData = utils.sheet_to_json(worksheet)

        const importedSucursales = jsonData.map(row => ({
          brandid: row.brandid || "",
          storeid: row.storeid || "",
          Nombretienda: row.Nombretienda || "",
          marca: row.marca || "",
          direccion: row.direccion || "",
          telefono: row.telefono || "",
          nombrecomercial: row.nombrecomercial || "",
        }))

        setSucursales(importedSucursales)

        const requestBody = {
          nit: id,
          sucursales: importedSucursales,
        }

        const storedUser = localStorage.getItem("authUser")
        const currentUser = storedUser ? JSON.parse(storedUser) : null
        const response = await axios.post(
          `${baseURL}empresas/sucursales`,
          requestBody,
          { headers: { Authorization: `Bearer ${currentUser.access}` } },
        )

        if (response.status === 200 || response.status === 201) {
          alert("Sucursales importadas correctamente")
          fetchSucursales(currentUser)
        }
      }
      reader.readAsArrayBuffer(file)
    } catch (error) {
      console.error("Error importando sucursales:", error)
      alert("Error al importar sucursales: " + error.message)
    } finally {
      setUploading(false)
      event.target.value = null
    }
  }

  const handleExportToExcel = () => {
    const worksheet = utils.json_to_sheet(sucursales)
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, worksheet, "Sucursales")
    writeFile(workbook, "Sucursales.xlsx")
  }

  const handleRemoveSucursal = index => {
    setSucursales(prev => prev.filter((_, i) => i !== index))
  }

  // Iniciar edición de una sucursal
  const handleEditSucursal = index => {
    setEditIndex(index)
    setEditedSucursal({ ...sucursales[index] })
  }

  // Manejar cambios en los campos editados
  const handleInputChange = (field, value) => {
    setEditedSucursal(prev => ({ ...prev, [field]: value }))
  }

  // Actualizar una sucursal específica
  const handleUpdateSucursal = async index => {
    setUpdatingId(sucursales[index]._id || index) // Usar _id si existe, sino el índice
    try {
      const storedUser = localStorage.getItem("authUser")
      const currentUser = storedUser ? JSON.parse(storedUser) : null

      const response = await axios.put(
        `${baseURL}empresas/sucursales/${id}`,
        editedSucursal,
        { headers: { Authorization: `Bearer ${currentUser.access}` } },
      )

      if (response.status === 200) {
        const updatedSucursales = [...sucursales]
        updatedSucursales[index] = editedSucursal
        setSucursales(updatedSucursales)
        setEditIndex(null)
        setEditedSucursal(null)
        alert("Sucursal actualizada correctamente")
      }
    } catch (error) {
      console.error("Error actualizando sucursal:", error)
      alert("Error al actualizar sucursal: " + error.message)
    } finally {
      setUpdatingId(null)
    }
  }

  const filteredSucursales = sucursales.filter(sucursal =>
    sucursal.Nombretienda.toLowerCase().includes(searchTerm.toLowerCase()),
  )

  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentItems = filteredSucursales.slice(
    indexOfFirstItem,
    indexOfLastItem,
  )

  const data = {
    columns: [
      { label: "Brand ID", field: "brandid", sort: "asc", width: 20 },
      { label: "Store ID", field: "storeid", sort: "asc", width: 75 },
      {
        label: "Nombre Tienda",
        field: "Nombretienda",
        sort: "asc",
        width: 200,
      },
      { label: "Marca", field: "marca", sort: "asc", width: 200 },
      { label: "Dirección", field: "direccion", sort: "asc", width: 200 },
      { label: "Teléfono", field: "telefono", sort: "asc", width: 200 },
      {
        label: "Nombre Comercial",
        field: "nombrecomercial",
        sort: "asc",
        width: 200,
      },
      { label: "Acciones", field: "acciones", sort: "disabled", width: 200 },
    ],
    rows: currentItems.map((sucursal, index) => ({
      brandid:
        editIndex === index ? (
          <Input
            value={editedSucursal.brandid}
            onChange={e => handleInputChange("brandid", e.target.value)}
          />
        ) : (
          sucursal.brandid
        ),
      storeid:
        editIndex === index ? (
          <Input
            value={editedSucursal.storeid}
            onChange={e => handleInputChange("storeid", e.target.value)}
          />
        ) : (
          sucursal.storeid
        ),
      Nombretienda:
        editIndex === index ? (
          <Input
            value={editedSucursal.Nombretienda}
            onChange={e => handleInputChange("Nombretienda", e.target.value)}
          />
        ) : (
          sucursal.Nombretienda
        ),
      marca:
        editIndex === index ? (
          <Input
            value={editedSucursal.marca}
            onChange={e => handleInputChange("marca", e.target.value)}
          />
        ) : (
          sucursal.marca
        ),
      direccion:
        editIndex === index ? (
          <Input
            value={editedSucursal.direccion}
            onChange={e => handleInputChange("direccion", e.target.value)}
          />
        ) : (
          sucursal.direccion
        ),
      telefono:
        editIndex === index ? (
          <Input
            value={editedSucursal.telefono}
            onChange={e => handleInputChange("telefono", e.target.value)}
          />
        ) : (
          sucursal.telefono
        ),
      nombrecomercial:
        editIndex === index ? (
          <Input
            value={editedSucursal.nombrecomercial}
            onChange={e => handleInputChange("nombrecomercial", e.target.value)}
          />
        ) : (
          sucursal.nombrecomercial
        ),
      acciones:
        editIndex === index ? (
          <>
            <Button
              color="success"
              onClick={() => handleUpdateSucursal(index)}
              disabled={updatingId === (sucursal._id || index)}
            >
              {updatingId === (sucursal._id || index) ? (
                <Spinner size="sm" />
              ) : (
                "Actualizar"
              )}
            </Button>
            <Button
              color="secondary"
              className="ms-2"
              onClick={() => setEditIndex(null)}
            >
              Cancelar
            </Button>
          </>
        ) : (
          <>
            <Button
              color="warning"
              className="me-2"
              onClick={() => handleEditSucursal(index)}
            >
              Editar
            </Button>
            <Button color="danger" onClick={() => handleRemoveSucursal(index)}>
              Eliminar
            </Button>
          </>
        ),
    })),
  }

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1)
  }

  const dataTableOptions = {
    lengthMenu: "Mostrar _MENU_ registros",
    zeroRecords: "No se encontraron registros coincidentes",
    info: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    infoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
    infoFiltered: "(filtrado de un total de _MAX_ registros)",
    search: "Buscar:",
    paginate: {
      first: "Primero",
      last: "Último",
      next: "Siguiente",
      previous: "Anterior",
    },
    aria: {
      sortAscending: ": activar para ordenar la columna de manera ascendente",
      sortDescending: ": activar para ordenar la columna de manera descendente",
    },
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle className="h4">Sucursales</CardTitle>
                <div>
                  <Button
                    color="primary"
                    className="me-2"
                    onClick={handleDownloadForm}
                  >
                    Descargar Formulario
                  </Button>
                  <label className="btn btn-success me-2">
                    {uploading ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      "Importar"
                    )}
                    <input
                      type="file"
                      accept=".xlsx, .xls"
                      hidden
                      onChange={handleImportExcel}
                      disabled={uploading}
                    />
                  </label>
                  <Button
                    style={{
                      backgroundColor: "#007ea7",
                      color: "white",
                      border: "1px solid rgb(46, 58, 73)",
                    }}
                    onClick={handleExportToExcel}
                    className="me-2"
                  >
                    Exportar a Excel
                  </Button>
                  <Button
                    color="danger"
                    onClick={() => navigate("/portal/consultar-empresas")}
                  >
                    Salir
                  </Button>
                </div>
              </div>
              <p className="card-title-desc">
                Visualice y gestione la información de las sucursales
                disponibles en el sistema.
              </p>

              {loading ? (
                <p>Cargando...</p>
              ) : (
                <MDBDataTable
                  responsive
                  bordered
                  data={data}
                  entriesLabel="Mostrar registros"
                  searchLabel="Buscar:"
                  paginationLabel={["Anterior", "Siguiente"]}
                  infoLabel={[
                    "Mostrando registros del",
                    "al",
                    "de un total de",
                    "registros",
                  ]}
                  language={dataTableOptions}
                  paging={false}
                  displayEntries
                />
              )}
            </CardBody>
          </Card>
          <Row>
            <Col md={12}>
              <ReactPaginate
                previousLabel={"← Anterior"}
                nextLabel={"Siguiente →"}
                pageCount={Math.ceil(filteredSucursales.length / itemsPerPage)}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStatetoProps = state => {
  return {
    user: state.Login.user,
  }
}

export default connect(mapStatetoProps, { setBreadcrumbItems })(Sucursales)
