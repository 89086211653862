import React, { useEffect, useState, useCallback } from "react"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  Input,
  Button,
} from "reactstrap"
import axios from "axios"
import * as XLSX from "xlsx"
import { Line } from "react-chartjs-2"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const baseURL = process.env.REACT_APP_API_URL

const Dashboard = ({ setBreadcrumbItems, user }) => {
  document.title = "LS-DA / Softlogy"
  const userGreeting = `Bienvenido, ${user ? `${user.nombre} ${user.apellido}` : "Usuario"}`

  // Estados
  const [empresas, setEmpresas] = useState([])
  const [loading, setLoading] = useState(true)
  const [clientesPorEmpresa, setClientesPorEmpresa] = useState([])
  const [sucursales, setSucursales] = useState([])
  const [consumoMensual, setConsumoMensual] = useState(null)
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState(null)
  const [fechaInicio, setFechaInicio] = useState("")
  const [fechaFin, setFechaFin] = useState("")

  // Configuración de fechas
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)
  const maxDate = yesterday.toLocaleDateString("en-CA")
  const defaultStartDate = new Date()
  defaultStartDate.setDate(defaultStartDate.getDate() - 30) // Últimos 30 días por defecto

  // Formateador de moneda
  const formatoCOP = new Intl.NumberFormat("es-CO", {
    style: "currency",
    currency: "COP",
    minimumFractionDigits: 2,
  })

  // Breadcrumb
  useEffect(() => {
    if (user) {
      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Dashboard", link: "#" },
      ]
      setBreadcrumbItems(userGreeting, breadcrumbItems)
    }
  }, [setBreadcrumbItems, user, userGreeting])

  // Fetch empresas
  const fetchEmpresas = useCallback(async currentUser => {
    if (!currentUser) return
    setLoading(true)

    try {
      if (currentUser.role === "SuperAdmin") {
        if (!currentUser.IdEmpresa || !currentUser.access) return
        const response = await axios.get(
          `${baseURL}empresas/obtener?id=${currentUser.IdEmpresa}`,
          { headers: { Authorization: `Bearer ${currentUser.access}` } },
        )
        const empresasData = Array.isArray(response.data)
          ? response.data
          : [response.data]
        setEmpresas(empresasData)
        if (empresasData.length > 0) setEmpresaSeleccionada(empresasData[0])
      } else {
        if (!currentUser.empresas) return
        setEmpresas(currentUser.empresas)
        if (currentUser.empresas.length > 0)
          setEmpresaSeleccionada(currentUser.empresas[0])
      }
    } catch (error) {
      console.error("Error fetching empresas:", error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if (user) fetchEmpresas(user)
  }, [user, fetchEmpresas])

  // Fetch datos con rango de fechas
  const fetchData = useCallback(
    async (endpoint, setData, transformData) => {
      if (!empresaSeleccionada || !fechaInicio || !fechaFin) return

      const nit =
        empresaSeleccionada.idempresa?.identificacion ||
        empresaSeleccionada.identificacion ||
        empresaSeleccionada.nit

      try {
        const url = `${baseURL}reportes/${endpoint}?nitEmisor=${nit}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`
        const response = await fetch(url)
        if (!response.ok) throw new Error(`Error fetching ${endpoint}`)
        const data = await response.json()
        setData(transformData(data, nit))
      } catch (error) {
        console.error(`Error fetching ${endpoint}:`, error)
        setData(transformData(null, nit))
      }
    },
    [empresaSeleccionada, fechaInicio, fechaFin],
  )

  // Transformaciones de datos
  const transformTopClientes = (data, nit) => {
    if (data?.topClientes?.length) {
      const topClientes = data.topClientes.map(item => ({
        nombre: item.nombreCliente,
        importe:
          parseFloat(item.totalImporte?.$numberDecimal || item.totalImporte) ||
          0,
      }))
      const totalClientes = topClientes.reduce(
        (acc, cliente) => acc + cliente.importe,
        0,
      )
      return [
        {
          nit,
          nombreEmpresa:
            empresaSeleccionada.idempresa?.nombre || empresaSeleccionada.nombre,
          topClientes,
          totalClientes,
        },
      ]
    }
    return [
      {
        nit,
        nombreEmpresa:
          empresaSeleccionada.idempresa?.nombre || empresaSeleccionada.nombre,
        topClientes: [],
        totalClientes: 0,
      },
    ]
  }

  const transformSucursales = (data, nit) => data?.detalle || []

  const transformConsumoMensual = data => data || null

  // Efectos para fetch de datos
  useEffect(() => {
    fetchData("top10", setClientesPorEmpresa, transformTopClientes)
  }, [fetchData])

  useEffect(() => {
    fetchData("sucursales", setSucursales, transformSucursales)
  }, [fetchData])

  useEffect(() => {
    fetchData("historico", setConsumoMensual, transformConsumoMensual)
  }, [fetchData])

  // Preparación de datos para gráficos
  const sortedDays =
    consumoMensual?.dias?.sort((a, b) => Number(a.dia) - Number(b.dia)) || []

  const createChartData = (label, dataKey, borderColor, backgroundColor) => ({
    labels: sortedDays.map(day => day.dia),
    datasets: [
      {
        label,
        data: sortedDays.map(day => day[dataKey]),
        borderColor,
        backgroundColor,
        fill: true,
        tension: 0.3,
        borderWidth: 2,
      },
    ],
  })

  const chartDataImporte =
    sortedDays.length > 0
      ? createChartData(
          "Total Importe",
          "totalImporte",
          "rgba(75, 192, 192, 1)",
          "rgba(75, 192, 192, 0.3)",
        )
      : null

  const chartDataDocumentos =
    sortedDays.length > 0
      ? createChartData(
          "Total Documentos",
          "totalDocumentos",
          "rgba(153, 102, 255, 1)",
          "rgba(153, 102, 255, 0.3)",
        )
      : null

  const createChartOptions = (title, yAxisLabel) => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { position: "top" },
      title: { display: true, text: title },
    },
    scales: {
      x: { title: { display: true, text: "Día" } },
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: { display: true, text: yAxisLabel },
        ticks: { beginAtZero: true },
      },
    },
  })

  // Indicadores

  const calculateIndicators = () => {
    const totalImporteMes = sortedDays.reduce(
      (sum, day) => sum + Number(day.totalImporte),
      0,
    )
    const totalDocumentosMes = sortedDays.reduce(
      (sum, day) => sum + Number(day.totalDocumentos),
      0,
    )
    const promedioImporte =
      totalDocumentosMes > 0 ? totalImporteMes / totalDocumentosMes : 0

    const crecimientosDiarios = []
    let crecimientoAcumulado = null

    if (sortedDays.length >= 2) {
      for (let i = 1; i < sortedDays.length; i++) {
        const currentImporte = Number(sortedDays[i].totalImporte)
        const prevImporte = Number(sortedDays[i - 1].totalImporte)
        const prevDia = sortedDays[i - 1].dia
        const currentDia = sortedDays[i].dia

        let crecimiento = "N/A"
        if (prevImporte > 0) {
          crecimiento = ((currentImporte - prevImporte) / prevImporte) * 100
          crecimiento = crecimiento.toFixed(2)
        }

        crecimientosDiarios.push({
          desde: prevDia,
          hasta: currentDia,
          importeDesde: prevImporte,
          importeHasta: currentImporte,
          crecimiento,
        })
      }

      const primerImporte = Number(sortedDays[0].totalImporte)
      const ultimoImporte = Number(
        sortedDays[sortedDays.length - 1].totalImporte,
      )
      if (primerImporte > 0) {
        crecimientoAcumulado =
          ((ultimoImporte - primerImporte) / primerImporte) * 100
      }
    }

    return {
      promedioImporte,
      crecimientosDiarios,
      crecimientoAcumulado,
      importeAcumuladoMes: totalImporteMes,
    }
  }

  const {
    promedioImporte,
    crecimientosDiarios,
    crecimientoAcumulado,
    importeAcumuladoMes,
  } = calculateIndicators()

  // Handlers
  const handleEmpresaChange = e => {
    const selectedId = e.target.value
    const selected = empresas.find(
      empresa =>
        (empresa.idempresa?.identificacion ||
          empresa.identificacion ||
          empresa.nit) === selectedId,
    )
    setEmpresaSeleccionada(selected)
  }

  const handleFechaInicioChange = e => setFechaInicio(e.target.value)
  const handleFechaFinChange = e => setFechaFin(e.target.value)

  // Funciones de descarga Excel
  const downloadExcel = (data, filename, sheetName) => {
    const ws = XLSX.utils.json_to_sheet(data)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, sheetName)
    XLSX.writeFile(wb, `${filename}.xlsx`)
  }

  const downloadClientesExcel = () => {
    if (!clientesPorEmpresa.length) return
    const data = [
      ...clientesPorEmpresa[0].topClientes.map(item => ({
        Cliente: item.nombre,
        Importe: item.importe,
      })),
      { Cliente: "TOTAL", Importe: clientesPorEmpresa[0].totalClientes },
    ]
    downloadExcel(data, "clientes", "Clientes")
  }

  const downloadSucursalesExcel = () => {
    if (!sucursales.length) return
    const data = [
      ...sucursales.map(s => ({
        Sucursal: s.nombreSucursal,
        Centro: s.storeid,
        Importe: s.totalImporte,
        Documentos: s.totalDocumentos,
      })),
      {
        Sucursal: "TOTAL",
        Centro: "",
        Importe: sucursales.reduce((acc, s) => acc + s.totalImporte, 0),
        Documentos: sucursales.reduce((acc, s) => acc + s.totalDocumentos, 0),
      },
    ]
    downloadExcel(data, "sucursales", "Sucursales")
  }

  // Render
  // ... (resto del código del componente Dashboard permanece igual hasta el render)

  return (
    <React.Fragment>
      {/* Filtro Global */}
      <Row className="mb-4">
        <Col xl="12">
          <Card className="mb-4 shadow-sm">
            <CardHeader className="d-flex justify-content-between align-items-center bg-primary text-white">
              <div>
                Dashboard -{" "}
                {empresaSeleccionada?.idempresa?.nombre ||
                  empresaSeleccionada?.nombre}
              </div>
              <div className="d-flex align-items-center gap-2">
                <Input
                  type="select"
                  value={
                    empresaSeleccionada?.idempresa?.identificacion ||
                    empresaSeleccionada?.identificacion ||
                    empresaSeleccionada?.nit ||
                    ""
                  }
                  onChange={handleEmpresaChange}
                  style={{ maxWidth: "150px" }}
                >
                  {empresas.map((empresa, index) => (
                    <option
                      key={index}
                      value={
                        empresa.idempresa?.identificacion ||
                        empresa.identificacion ||
                        empresa.nit
                      }
                    >
                      {empresa.idempresa?.nombre || empresa.nombre}
                    </option>
                  ))}
                </Input>
                <Input
                  type="date"
                  value={fechaInicio}
                  onChange={handleFechaInicioChange}
                  max={maxDate}
                  style={{ maxWidth: "150px" }}
                  placeholder="Fecha Inicio"
                />
                <Input
                  type="date"
                  value={fechaFin}
                  onChange={handleFechaFinChange}
                  max={maxDate}
                  style={{ maxWidth: "150px" }}
                  placeholder="Fecha Fin"
                />
              </div>
            </CardHeader>
          </Card>
        </Col>
      </Row>

      {/* Indicadores (sin Crecimiento Diario) */}
      <Row className="mb-4">
        <Col md="4">
          <Card className="mb-4 shadow-sm">
            <CardHeader>
              <h6 className="mb-0">Promedio Importe por Documento</h6>
            </CardHeader>
            <CardBody>
              <h4>{formatoCOP.format(promedioImporte)}</h4>
            </CardBody>
          </Card>
        </Col>
        <Col md="4">
          <Card className="mb-4 shadow-sm">
            <CardHeader>
              <h6 className="mb-0">Importe Acumulado</h6>
            </CardHeader>
            <CardBody>
              <h4>{formatoCOP.format(importeAcumuladoMes)}</h4>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Tablas */}
      <Row>
        {/* Top 10 Clientes */}
        <Col md="6">
          <Card className="mb-4 shadow-sm">
            <CardHeader className="text-black d-flex justify-content-between align-items-center">
              <h6 className="mb-0">Top 10 Clientes</h6>
              <Button color="info" size="sm" onClick={downloadClientesExcel}>
                Descargar a Excel
              </Button>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table className="align-middle table-centered table-vertical table-nowrap">
                  <thead>
                    <tr>
                      <th>Cliente</th>
                      <th className="text-end">Importe</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clientesPorEmpresa.map(empresaData =>
                      empresaData.topClientes.map((cliente, index) => (
                        <tr key={index}>
                          <td>{cliente.nombre}</td>
                          <td className="text-end">
                            {formatoCOP.format(cliente.importe)}
                          </td>
                        </tr>
                      )),
                    )}
                    {clientesPorEmpresa.map((empresaData, index) => (
                      <tr key={index}>
                        <td className="fw-bold">TOTAL</td>
                        <td className="text-end fw-bold">
                          {formatoCOP.format(empresaData.totalClientes)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
        {/* Ventas por Sucursal */}
        <Col md="6">
          <Card className="mb-4 shadow-sm">
            <CardHeader className="text-black d-flex justify-content-between align-items-center">
              <h6 className="mb-0">Ventas por Sucursal</h6>
              <Button color="info" size="sm" onClick={downloadSucursalesExcel}>
                Descargar a Excel
              </Button>
            </CardHeader>
            <CardBody>
              <div className="table-responsive">
                <Table className="table-centered mb-0" responsive>
                  <thead>
                    <tr>
                      <th>Sucursal</th>
                      <th>Centro</th>
                      <th className="text-end">Importe</th>
                      <th className="text-end">Documentos</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sucursales.map((sucursal, index) => (
                      <tr key={index}>
                        <td>{sucursal.nombreSucursal}</td>
                        <td>{sucursal.storeid}</td>
                        <td className="text-end">
                          {formatoCOP.format(sucursal.totalImporte)}
                        </td>
                        <td className="text-end">{sucursal.totalDocumentos}</td>
                      </tr>
                    ))}
                    <tr>
                      <td className="fw-bold">TOTAL</td>
                      <td></td>
                      <td className="text-end fw-bold">
                        {formatoCOP.format(
                          sucursales.reduce(
                            (acc, s) => acc + s.totalImporte,
                            0,
                          ),
                        )}
                      </td>
                      <td className="text-end fw-bold">
                        {sucursales.reduce(
                          (acc, s) => acc + s.totalDocumentos,
                          0,
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Gráficos */}
      <Row>
        <Col md="6">
          <Card className="mb-4 shadow-sm">
            <CardHeader className="text-black">
              <h6 className="mb-0">Total Importe</h6>
            </CardHeader>
            <CardBody>
              {chartDataImporte && (
                <div style={{ width: "100%", height: "300px" }}>
                  <Line
                    data={chartDataImporte}
                    options={createChartOptions("Total Importe", "Importe")}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
        <Col md="6">
          <Card className="mb-4 shadow-sm">
            <CardHeader className="text-black">
              <h6 className="mb-0">Total Documentos</h6>
            </CardHeader>
            <CardBody>
              {chartDataDocumentos && (
                <div style={{ width: "100%", height: "300px" }}>
                  <Line
                    data={chartDataDocumentos}
                    options={createChartOptions(
                      "Total Documentos",
                      "Documentos",
                    )}
                  />
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Nueva Tabla de Crecimiento Diario al Final */}
      <Row>
        <Col md="12">
          <Card className="mb-4 shadow-sm">
            <CardHeader className="text-black">
              <h6 className="mb-0">Crecimiento Diario</h6>
            </CardHeader>
            <CardBody>
              {crecimientosDiarios.length > 0 ? (
                <div className="table-responsive">
                  <Table className="table-centered mb-0" responsive>
                    <thead>
                      <tr>
                        <th>Desde</th>
                        <th>Hasta</th>
                        <th className="text-end">Importe Desde</th>
                        <th className="text-end">Importe Hasta</th>
                        <th className="text-end">Crecimiento</th>
                      </tr>
                    </thead>
                    <tbody>
                      {crecimientosDiarios.map((item, index) => (
                        <tr key={index}>
                          <td>{item.desde}</td>
                          <td>{item.hasta}</td>
                          <td className="text-end">
                            {formatoCOP.format(item.importeDesde)}
                          </td>
                          <td className="text-end">
                            {formatoCOP.format(item.importeHasta)}
                          </td>
                          <td className="text-end">
                            {item.crecimiento === "N/A"
                              ? "N/A"
                              : `${item.crecimiento}%`}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="4" className="fw-bold text-end">
                          Crecimiento Acumulado:
                        </td>
                        <td className="text-end fw-bold">
                          {crecimientoAcumulado !== null
                            ? `${crecimientoAcumulado.toFixed(2)}%`
                            : "N/A"}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              ) : (
                <p>
                  No hay datos suficientes para mostrar el crecimiento diario
                </p>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  user: state.Login.user,
})

export default connect(mapStateToProps, { setBreadcrumbItems })(Dashboard)
