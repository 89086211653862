import React, { useEffect, useState, useCallback } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Spinner,
  Input,
} from "reactstrap"
import { connect } from "react-redux"
import axios from "axios"
import classnames from "classnames"
import { useParams, useNavigate } from "react-router-dom"
import ReactPaginate from "react-paginate"
import Select from "react-select"
import { setBreadcrumbItems } from "../../store/actions"
import { utils, read } from "xlsx"
import "./Empresas.scss"

const baseURL =
  process.env.REACT_APP_API_URL || "http://localhost:3980/hub-latam/rc1/"

const Resoluciones = props => {
  document.title = "Resoluciones | LS-DA - Softlogy"

  const { nit } = useParams()
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState("1")
  const [resolucionesAsignadas, setResolucionesAsignadas] = useState([])
  const [resolucionesSinAsignar, setResolucionesSinAsignar] = useState([])
  const [sucursales, setSucursales] = useState([])
  const [loading, setLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage] = useState(10)
  const [modal, setModal] = useState(false)
  const [ncModal, setNcModal] = useState(false)
  const [selectedResolution, setSelectedResolution] = useState(null)
  const [selectedSucursal, setSelectedSucursal] = useState("")
  const [fechaInicio, setFechaInicio] = useState(
    new Date().toISOString().substr(0, 10),
  )
  const [horaInicio, setHoraInicio] = useState(
    new Date().toISOString().substr(11, 5),
  )
  const [ncPrefijo, setNcPrefijo] = useState("")
  const [updatingFolio, setUpdatingFolio] = useState(false)
  const [updatingResoluciones, setUpdatingResoluciones] = useState(false)
  const [assigning, setAssigning] = useState(false)
  const [unassigningId, setUnassigningId] = useState(null)
  const [deletingId, setDeletingId] = useState(null)
  const [importingNc, setImportingNc] = useState(false) // Renombrado para diferenciar
  const [importingFa, setImportingFa] = useState(false) // Nuevo estado para FA

  const fetchResoluciones = useCallback(async () => {
    if (!nit) {
      console.log("No nit provided, skipping fetch.")
      setLoading(false)
      return
    }

    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    setLoading(true)
    try {
      const [responseAsignadas, responseSinAsignar, responseSucursales] =
        await Promise.all([
          axios.get(`${baseURL}resoluciones/obtener?nit=${nit}`),
          axios.get(
            `${baseURL}resoluciones/obtener?nit=${nit}&sinSucursal=true`,
          ),
          axios.get(`${baseURL}empresas/sucursales?id=${nit}`, {
            headers: { Authorization: `Bearer ${currentUser?.access || ""}` },
          }),
        ])

      const asignadasData = Array.isArray(responseAsignadas.data)
        ? responseAsignadas.data
        : []
      const sinAsignarData = Array.isArray(responseSinAsignar.data)
        ? responseSinAsignar.data
        : []
      const sucursalesData = responseSucursales.data?.sucursales || []

      console.log("Resoluciones asignadas:", asignadasData)

      setResolucionesAsignadas(asignadasData)
      setResolucionesSinAsignar(sinAsignarData)
      setSucursales(sucursalesData)
    } catch (error) {
      console.error("Error fetching resoluciones data:", error)
      setResolucionesAsignadas([])
      setResolucionesSinAsignar([])
      setSucursales([])
    } finally {
      setLoading(false)
    }
  }, [nit])

  useEffect(() => {
    fetchResoluciones()
  }, [fetchResoluciones])

  const toggleModal = resolution => {
    setSelectedResolution(resolution)
    setModal(!modal)
    if (!resolution) {
      setSelectedSucursal("")
    }
  }

  const toggleNcModal = resolution => {
    setSelectedResolution(resolution)
    setNcModal(!ncModal)
    setNcPrefijo("")
  }

  const handleAssign = async () => {
    if (!selectedResolution || !selectedSucursal) {
      alert("Por favor, seleccione una sucursal.")
      return
    }

    setAssigning(true)
    try {
      const sucursalSeleccionada = sucursales.find(
        sucursal => sucursal._id === selectedSucursal,
      )
      if (!sucursalSeleccionada) {
        throw new Error("Sucursal no encontrada")
      }

      const { brandid, storeid } = sucursalSeleccionada
      const prefijo = selectedResolution.prefijo.prefijo

      const resolucionConPrefijo = resolucionesAsignadas.find(r =>
        r.prefijos.some(
          p =>
            p.prefijo === prefijo &&
            p.sucursal &&
            p.sucursal.toString() !== selectedSucursal,
        ),
      )
      if (resolucionConPrefijo) {
        throw new Error(
          `El prefijo ${prefijo} ya está asociado a otra sucursal.`,
        )
      }

      console.log("Enviando solicitud de asignación:", {
        nit,
        brandid,
        storeid,
        prefijo,
        fechaInicio,
      })

      const response = await axios.post(`${baseURL}resoluciones/sucursal`, {
        nit,
        brandid,
        storeid,
        prefijo,
        fechaInicio,
      })

      console.log("Respuesta del backend (asignación):", response.data)

      if (response.status === 200) {
        const resolucion = resolucionesAsignadas.find(r =>
          r.prefijos.some(p => p.prefijo === prefijo),
        )
        if (resolucion) {
          const prefijoOriginal = resolucion.prefijos.find(
            p => p.prefijo === prefijo,
          )
          const ncAsociadas = resolucion.prefijos.filter(
            p =>
              (p.tipodocumento === "Nota de Credito Electronica" ||
                p.tipodocumento === "Nota de Credito Contingencia") &&
              p.prefijoOriginal &&
              p.prefijoOriginal.toString() === prefijoOriginal._id.toString() &&
              !p.sucursal,
          )

          if (ncAsociadas.length > 0) {
            for (const nc of ncAsociadas) {
              console.log(
                `Actualizando sucursal para NC ${nc.prefijo} con sucursalId ${selectedSucursal}`,
              )
              const updateResponse = await axios.post(
                `${baseURL}resoluciones/updateNcSucursal`,
                {
                  nit,
                  prefijoNC: nc.prefijo,
                  sucursalId: selectedSucursal,
                },
              )

              console.log(
                `Respuesta del backend (update NC):`,
                updateResponse.data,
              )

              if (updateResponse.status !== 200) {
                console.warn(
                  `No se pudo actualizar la sucursal para NC ${nc.prefijo}`,
                )
              }
            }
          }
        }

        alert("Resolución asignada correctamente")
        setModal(false)
        fetchResoluciones()
      } else {
        throw new Error(`Respuesta inesperada del servidor: ${response.status}`)
      }
    } catch (error) {
      console.error(
        "Error asignando resolución:",
        error.response?.data || error.message,
      )
      alert(
        `Error al asignar resolución: ${error.response?.data?.message || error.message}`,
      )
    } finally {
      setAssigning(false)
    }
  }

  const handleUnassign = async prefijo => {
    setUnassigningId(prefijo._id)
    try {
      const response = await axios.post(
        `${baseURL}resoluciones/sucursal/desasignar`,
        {
          nit,
          prefijo: prefijo.prefijo,
        },
      )

      console.log("Respuesta del backend (desvincular):", response.data)

      if (response.status === 200) {
        alert("Sucursal desvinculada correctamente")
        fetchResoluciones()
      } else {
        throw new Error(`Respuesta inesperada del servidor: ${response.status}`)
      }
    } catch (error) {
      console.error(
        "Error desvinculando resolución:",
        error.response?.data || error.message,
      )
      alert(
        `Error al desvincular resolución: ${error.response?.data?.message || error.message}`,
      )
    } finally {
      setUnassigningId(null)
    }
  }

  const handleNcSubmit = async () => {
    if (!ncPrefijo) {
      alert("Por favor, ingrese un prefijo para la Nota de Crédito.")
      return
    }

    try {
      const prefijoOriginal = selectedResolution.prefijo.prefijo
      const duplicaciones = [
        {
          prefijo: prefijoOriginal,
          prefijoNC: ncPrefijo,
        },
      ]

      console.log("Enviando solicitud NC:", { nit, duplicaciones })

      const response = await axios.post(`${baseURL}resoluciones/prefijoNC`, {
        nit,
        duplicaciones,
      })

      console.log("Respuesta del backend (NC):", response.data)

      if (response.status === 200) {
        alert("Nota de Crédito configurada correctamente")
        setNcModal(false)
        fetchResoluciones()
      } else {
        throw new Error(`Respuesta inesperada del servidor: ${response.status}`)
      }
    } catch (error) {
      console.error(
        "Error configurando Nota de Crédito:",
        error.response?.data || error.message,
      )
      alert(
        `Error al configurar Nota de Crédito: ${error.response?.data?.message || error.message}`,
      )
    }
  }

  const handleDeleteNc = async prefijo => {
    setDeletingId(prefijo._id)
    try {
      const response = await axios.post(
        `${baseURL}resoluciones/prefijoNC/eliminar`,
        {
          nit,
          prefijoNC: prefijo.prefijo,
        },
      )

      console.log("Respuesta del backend (eliminar NC):", response.data)

      if (response.status === 200) {
        alert("Prefijo de Nota de Crédito eliminado correctamente")
        fetchResoluciones()
      } else {
        throw new Error(`Respuesta inesperada del servidor: ${response.status}`)
      }
    } catch (error) {
      console.error(
        "Error eliminando prefijo NC:",
        error.response?.data || error.message,
      )
      alert(
        `Error al eliminar prefijo NC: ${error.response?.data?.message || error.message}`,
      )
    } finally {
      setDeletingId(null)
    }
  }

  const handleImportNcPrefijos = async event => {
    const file = event.target.files[0]
    if (!file) return

    setImportingNc(true)
    try {
      const reader = new FileReader()
      reader.onload = async e => {
        try {
          const data = new Uint8Array(e.target.result)
          const workbook = read(data, { type: "array" })
          const worksheet = workbook.Sheets[workbook.SheetNames[0]]
          const jsonData = utils.sheet_to_json(worksheet, { header: 1 })

          const headers = jsonData[0]
          const requiredHeaders = ["prefijo", "prefijoNC"]
          const missingHeaders = requiredHeaders.filter(
            header => !headers.includes(header),
          )
          if (missingHeaders.length > 0) {
            alert(
              `Faltan los encabezados: ${missingHeaders.join(", ")}. Los encabezados deben ser 'prefijo' y 'prefijoNC'.`,
            )
            return
          }

          const duplicaciones = jsonData
            .slice(1)
            .map(row => ({
              prefijo: row[headers.indexOf("prefijo")] || "",
              prefijoNC: row[headers.indexOf("prefijoNC")] || "",
            }))
            .filter(row => row.prefijo && row.prefijoNC)

          if (duplicaciones.length === 0) {
            alert("El archivo no contiene datos válidos para importar.")
            return
          }

          console.log("Importando prefijos NC:", { nit, duplicaciones })

          const response = await axios.post(
            `${baseURL}resoluciones/prefijoNC`,
            {
              nit,
              duplicaciones,
            },
          )

          console.log("Respuesta del backend (importar NC):", response.data)

          if (response.status === 200) {
            alert("Prefijos NC importados correctamente")
            fetchResoluciones()
          } else {
            alert(`Respuesta inesperada del servidor: ${response.status}`)
          }
        } catch (innerError) {
          console.error("Error procesando el archivo:", innerError.message)
          alert(`Error al procesar el archivo: ${innerError.message}`)
        }
      }
      reader.readAsArrayBuffer(file)
    } catch (error) {
      console.error(
        "Error importando prefijos NC:",
        error.response?.data || error.message,
      )
      alert(
        `Error al importar prefijos NC: ${error.response?.data?.message || error.message}`,
      )
    } finally {
      setImportingNc(false)
      event.target.value = null
    }
  }

  const handleImportFaAsignaciones = async event => {
    const file = event.target.files[0]
    if (!file) return

    setImportingFa(true)
    try {
      const reader = new FileReader()
      reader.onload = async e => {
        try {
          const data = new Uint8Array(e.target.result)
          const workbook = read(data, { type: "array" })
          const worksheet = workbook.Sheets[workbook.SheetNames[0]]
          const jsonData = utils.sheet_to_json(worksheet, { header: 1 })

          const headers = jsonData[0]
          const requiredHeaders = ["prefijo", "brandid", "storeid"]
          const missingHeaders = requiredHeaders.filter(
            header => !headers.includes(header),
          )
          if (missingHeaders.length > 0) {
            alert(
              `Faltan los encabezados: ${missingHeaders.join(", ")}. Los encabezados deben ser 'prefijo', 'brandid' y 'storeid'.`,
            )
            return
          }

          const asignaciones = jsonData
            .slice(1)
            .map(row => ({
              prefijo: String(row[headers.indexOf("prefijo")] || ""), // Asegurar que sea string
              brandid: String(row[headers.indexOf("brandid")] || ""), // Asegurar que sea string
              storeid: String(row[headers.indexOf("storeid")] || ""), // Asegurar que sea string
              fechaInicio: new Date().toISOString().substr(0, 10),
            }))
            .filter(row => row.prefijo && row.brandid && row.storeid)

          if (asignaciones.length === 0) {
            alert("El archivo no contiene datos válidos para importar.")
            return
          }

          console.log("Importando asignaciones FA:", { nit, asignaciones })

          const response = await axios.post(
            `${baseURL}resoluciones/importFaAsignaciones`,
            {
              nit,
              asignaciones,
            },
          )

          console.log("Respuesta del backend (importar FA):", response.data)

          if (response.status === 200) {
            alert("Asignaciones de prefijos FA importadas correctamente")
            fetchResoluciones()
          } else {
            alert(`Respuesta inesperada del servidor: ${response.status}`)
          }
        } catch (innerError) {
          console.error("Error procesando el archivo:", innerError.message)
          alert(`Error al procesar el archivo: ${innerError.message}`)
        }
      }
      reader.readAsArrayBuffer(file)
    } catch (error) {
      console.error(
        "Error importando asignaciones FA:",
        error.response?.data || error.message,
      )
      alert(
        `Error al importar asignaciones FA: ${error.response?.data?.message || error.message}`,
      )
    } finally {
      setImportingFa(false)
      event.target.value = null
    }
  }
  const handleActualizarFolio = async () => {
    setUpdatingFolio(true)
    try {
      const response = await axios.post(`${baseURL}resoluciones/ultimofolio`, {
        nit,
      })
      if (response.status === 200) {
        alert("Prefijos actualizados correctamente")
        fetchResoluciones()
      }
    } catch (error) {
      console.error("Error actualizando folios de prefijos:", error)
      alert("Error actualizando folios de prefijos.")
    } finally {
      setUpdatingFolio(false)
    }
  }

  const handleActualizarResoluciones = async () => {
    setUpdatingResoluciones(true)
    try {
      const response = await axios.post(
        "https://timbrador.ls-da.com/hub-latam/rc1/resoluciones/crear",
        { nit: nit },
      )
      if (response.status === 200 || response.status === 201) {
        alert("Resoluciones actualizadas correctamente")
        fetchResoluciones()
      }
    } catch (error) {
      console.error("Error actualizando resoluciones:", error)
      alert("Error al actualizar resoluciones: " + error.message)
    } finally {
      setUpdatingResoluciones(false)
    }
  }

  const dataTableOptions = {
    lengthMenu: "Mostrar _MENU_ registros",
    zeroRecords: "No se encontraron registros coincidentes",
    info: "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    infoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
    infoFiltered: "(filtrado de un total de _MAX_ registros)",
    search: "Buscar:",
    paginate: {
      first: "Primero",
      last: "Último",
      next: "Siguiente",
      previous: "Anterior",
    },
    aria: {
      sortAscending: ": activar para ordenar la columna de manera ascendente",
      sortDescending: ": activar para ordenar la columna de manera descendente",
    },
  }

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const handlePageClick = data => {
    setCurrentPage(data.selected)
  }

  const hasNc = (resolucion, prefijoId) => {
    return resolucion.prefijos.some(
      p =>
        (p.tipodocumento === "Nota de Credito Electronica" ||
          p.tipodocumento === "Nota de Credito Contingencia") &&
        p.prefijoOriginal &&
        p.prefijoOriginal.toString() === prefijoId.toString(),
    )
  }

  const renderTable = (
    resoluciones = [],
    title,
    isSinAsignar = false,
    tipoDocumento,
  ) => {
    if (!Array.isArray(resoluciones) || resoluciones.length === 0) {
      return <div>No hay resoluciones para mostrar.</div>
    }

    const rows = resoluciones.flatMap(resolucion => {
      const prefijosFiltrados = resolucion.prefijos.filter(
        prefijo => prefijo.tipodocumento === tipoDocumento,
      )

      return prefijosFiltrados.map(prefijo => ({
        ncStatus: hasNc(resolucion, prefijo._id) ? (
          <span className="text-success">✔</span>
        ) : (
          <span className="text-warning">⚠</span>
        ),
        ultimofolio: prefijo.ulitmoFolio || prefijo.ultimoFolio || "N/A",
        ultimafecha: prefijo.fechaUltimoFolio || "N/A",
        resolucion: resolucion.numeroResolucion,
        fechaInicial: new Date(resolucion.fechaInicio).toLocaleDateString(),
        fechaVencimiento: new Date(resolucion.fechaFinal).toLocaleDateString(),
        prefijo: prefijo.prefijo,
        inicial: prefijo.folioInicial,
        final: prefijo.folioFinal,
        claveTecnica: prefijo.claveTecnica,
        tipoResolucion: prefijo.tipodocumento,
        sucursal: prefijo.sucursal ? (
          <div>
            {prefijo.sucursal.Nombretienda}
            {!isSinAsignar &&
              tipoDocumento !== "Nota de Credito Electronica" &&
              tipoDocumento !== "Nota de Credito Contingencia" && (
                <>
                  <Button
                    color="danger"
                    size="sm"
                    className="ms-2"
                    onClick={() => handleUnassign(prefijo)}
                    disabled={unassigningId === prefijo._id}
                  >
                    {unassigningId === prefijo._id ? (
                      <Spinner size="sm" />
                    ) : (
                      "Desvincular"
                    )}
                  </Button>
                  {!hasNc(resolucion, prefijo._id) && (
                    <Button
                      color="info"
                      size="sm"
                      className="ms-2"
                      onClick={() => toggleNcModal({ resolucion, prefijo })}
                    >
                      NC
                    </Button>
                  )}
                </>
              )}
            {!isSinAsignar &&
              (tipoDocumento === "Nota de Credito Electronica" ||
                tipoDocumento === "Nota de Credito Contingencia") && (
                <Button
                  color="danger"
                  size="sm"
                  className="ms-2"
                  onClick={() => handleDeleteNc(prefijo)}
                  disabled={deletingId === prefijo._id}
                >
                  {deletingId === prefijo._id ? (
                    <Spinner size="sm" />
                  ) : (
                    "Eliminar"
                  )}
                </Button>
              )}
          </div>
        ) : isSinAsignar ? (
          <Button
            className="btn btn-success"
            onClick={() => toggleModal({ resolucion, prefijo })}
          >
            Asignar
          </Button>
        ) : (
          "Sin Sucursal"
        ),
        id: prefijo._id,
      }))
    })

    if (rows.length === 0) {
      return <div>No hay resoluciones para mostrar.</div>
    }

    const offset = currentPage * itemsPerPage
    const currentPageData = rows.slice(offset, offset + itemsPerPage)

    return (
      <div>
        <MDBDataTable
          responsive
          bordered
          striped
          hover
          data={{
            columns: [
              {
                label: "Prefijo NC",
                field: "ncStatus",
                sort: "asc",
                width: 100,
              },
              {
                label: "Ultimo Folio",
                field: "ultimofolio",
                sort: "asc",
                width: 150,
              },
              {
                label: "Ultima Fecha",
                field: "ultimafecha",
                sort: "asc",
                width: 150,
              },
              {
                label: "Resolución",
                field: "resolucion",
                sort: "asc",
                width: 150,
              },
              {
                label: "Fecha Inicial",
                field: "fechaInicial",
                sort: "asc",
                width: 150,
              },
              {
                label: "Fecha Vencimiento",
                field: "fechaVencimiento",
                sort: "asc",
                width: 150,
              },
              { label: "Prefijo", field: "prefijo", sort: "asc", width: 100 },
              { label: "Inicial", field: "inicial", sort: "asc", width: 100 },
              { label: "Final", field: "final", sort: "asc", width: 100 },
              {
                label: "Clave Técnica",
                field: "claveTecnica",
                sort: "asc",
                width: 250,
              },
              {
                label: "Tipo Resolución",
                field: "tipoResolucion",
                sort: "asc",
                width: 150,
              },
              {
                label: isSinAsignar ? "Acción" : "Sucursal",
                field: "sucursal",
                sort: "asc",
                width: 350,
              },
            ],
            rows: currentPageData,
          }}
          entriesLabel="Mostrar _MENU_ registros"
          searchLabel="Buscar:"
          paginationLabel={["Anterior", "Siguiente"]}
          infoLabel={[
            "Mostrando registros del",
            "al",
            "de un total de",
            "registros",
          ]}
          language={dataTableOptions}
          paging={false}
          displayEntries={false}
        />
        <ReactPaginate
          previousLabel={"← Anterior"}
          nextLabel={"Siguiente →"}
          pageCount={Math.ceil(rows.length / itemsPerPage)}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
        />
      </div>
    )
  }

  const optionGroup = sucursales.map(sucursal => ({
    value: sucursal._id,
    label: sucursal.Nombretienda,
  }))

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <CardTitle className="h4">Resoluciones</CardTitle>
                <div>
                  <Button
                    color="primary"
                    className="me-2"
                    onClick={handleActualizarFolio}
                    disabled={updatingFolio}
                  >
                    {updatingFolio ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      "Actualizar Ultimos Folios"
                    )}
                  </Button>
                  <Button
                    color="success"
                    className="me-2"
                    onClick={handleActualizarResoluciones}
                    disabled={updatingResoluciones}
                  >
                    {updatingResoluciones ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      "Actualizar Resoluciones"
                    )}
                  </Button>
                  <label className="btn btn-warning me-2">
                    {importingNc ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      "Importar Prefijos NC"
                    )}
                    <input
                      type="file"
                      accept=".xlsx, .xls"
                      hidden
                      onChange={handleImportNcPrefijos}
                      disabled={importingNc}
                    />
                  </label>
                  <label className="btn btn-info me-2">
                    {importingFa ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      "Importar Asignaciones FA"
                    )}
                    <input
                      type="file"
                      accept=".xlsx, .xls"
                      hidden
                      onChange={handleImportFaAsignaciones}
                      disabled={importingFa}
                    />
                  </label>
                  <Button
                    color="danger"
                    onClick={() => navigate("/portal/consultar-empresas")}
                  >
                    Salir
                  </Button>
                </div>
              </div>
              <p className="card-title-desc">
                Visualice y gestione las resoluciones asignadas y sin asignar a
                las sucursales.
              </p>

              {loading ? (
                <p>Cargando...</p>
              ) : importingNc || importingFa ? (
                <p>
                  Procesando importación...{" "}
                  <Spinner size="sm" color="primary" />
                </p>
              ) : (
                <div>
                  <Nav pills className="navtab-bg nav-justified">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => toggleTab("1")}
                      >
                        Asignadas
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => toggleTab("2")}
                      >
                        Sin Asignar
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col md="12">
                          <h5>Facturas Electrónicas</h5>
                          {renderTable(
                            resolucionesAsignadas,
                            "Facturas Electrónicas",
                            false,
                            "Factura Electronica",
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <h5>Resoluciones de Contingencia</h5>
                          {renderTable(
                            resolucionesAsignadas,
                            "Resoluciones de Contingencia",
                            false,
                            "Resolucion de Contingencia",
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <h5>Notas de Crédito de Factura Electrónica</h5>
                          {renderTable(
                            resolucionesAsignadas,
                            "Notas de Crédito de Factura Electrónica",
                            false,
                            "Nota de Credito Electronica",
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <h5>Notas de Crédito de Contingencia</h5>
                          {renderTable(
                            resolucionesAsignadas,
                            "Notas de Crédito de Contingencia",
                            false,
                            "Nota de Credito Contingencia",
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Col md="12">
                          <h5>Facturas Electrónicas</h5>
                          {renderTable(
                            resolucionesSinAsignar,
                            "Facturas Electrónicas",
                            true,
                            "Factura Electronica",
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <h5>Resoluciones de Contingencia</h5>
                          {renderTable(
                            resolucionesSinAsignar,
                            "Resoluciones de Contingencia",
                            true,
                            "Resolucion de Contingencia",
                          )}
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Modal para Asignar */}
      <Modal isOpen={modal} toggle={() => toggleModal(null)}>
        <ModalHeader toggle={() => toggleModal(null)}>
          Asignar Sucursal
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="sucursal">Seleccione una Sucursal</Label>
            <Select
              value={optionGroup.find(
                option => option.value === selectedSucursal,
              )}
              onChange={option => setSelectedSucursal(option.value)}
              options={optionGroup}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
          <FormGroup>
            <Label for="fechaInicio">Fecha de inicio</Label>
            <Input
              type="date"
              id="fechaInicio"
              value={fechaInicio}
              onChange={e => setFechaInicio(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="horaInicio">Hora de inicio</Label>
            <Input
              type="time"
              id="horaInicio"
              value={horaInicio}
              onChange={e => setHoraInicio(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAssign} disabled={assigning}>
            {assigning ? <Spinner size="sm" color="light" /> : "Asignar"}
          </Button>{" "}
          <Button color="secondary" onClick={() => toggleModal(null)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      {/* Modal para Nota de Crédito */}
      <Modal isOpen={ncModal} toggle={() => toggleNcModal(null)}>
        <ModalHeader toggle={() => toggleNcModal(null)}>
          Configurar Nota de Crédito
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="ncPrefijo">Prefijo de Nota de Crédito</Label>
            <Input
              type="text"
              id="ncPrefijo"
              value={ncPrefijo}
              onChange={e => setNcPrefijo(e.target.value)}
              placeholder="Ingrese el prefijo para la Nota de Crédito"
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleNcSubmit}>
            Guardar
          </Button>{" "}
          <Button color="secondary" onClick={() => toggleNcModal(null)}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  return {
    user: state.Login.user,
  }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(Resoluciones)
